* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    font-size: 'Raleway', 'Lato', 'Nunito', sans-serif;
    width: 100%;
    background: #254234;
}

.header--container {
    position: relative;
    z-index: 1000;
} 

.header-list--container {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    padding: 0px 20px;
}

.header--list-items {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 38px;
    text-align: center;
    padding: 15px 30px;
}

.header--href {
    color: black;
    text-decoration: none
}

.header--href:hover {
    /*METER ANIMACIÓN ACA*/
    text-decoration: underline;
}

/* MAIN */

.main--container {
    position: absolute;
    top: 0px
}

.main--homeimage {
    width: 100%;
}

.main--title-container{
    position: absolute;
    top: 10%;
    font-family: 'Raleway', sans-serif;
    padding-left: 70px;
    color: white;
}

.main--logo {
    width: 125px; 
    height: 105px;
}

span {
    font-weight: 700;
}

.main--title {
    position: absolute;
    top: 70px;
    font-weight: 500;
    font-size: 5rem;
    line-height: 150px;
}

.main--title-content {
    position: absolute;
    top: 200px;
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 47px;
    width: 400px
}

.main--button-toapp {
    position: absolute;
    top: 350px;
    left: 0;
    margin-left: 70px;

    width: 120%;
    height: auto;

    background: #254234;
    box-shadow: 4px 5px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: none;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 67px;
    text-align: center;

    color: #FFFFFF;
}

/*CARD*/

.card--container-grid {
    display: flex;
    justify-content: center;
    margin: 0 250px;

    position: absolute;
    top: 650px;

    
}

.card--grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    gap: 100px;

    color: #ffffff;

    margin-bottom: 100px;
}

.card--container {
    background: #784C46;
    border-radius: 10px;
    padding: 50px 15px 30px
}

.bird--image {
    width: 70px;
    height: 60px;
}

.people--image {
    width: 65px;
    height: 65px;
}

.card--container > h4 {
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 2rem;
line-height: 3rem;
color: #FFFFFF;
}

.card--container > p {
    font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 1.25rem;
line-height: 1.5rem;
}

/*FOOTER*/

.footer--container {
    display: flex;
    justify-content: center;

    text-align: center;
}

footer {
    position: absolute;
    top: 1150px;

    width: 100%;
    height: auto;

    background: #17261F;

    color: #fff;
}

footer > img {
    width: 100px;
    /* height: 90px */
}

.footer--title {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2rem;
}

.mail--container {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-bottom: 20px;
}

@media (min-width: 1920px) {
    .header--list-items {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 2rem;
        line-height: 38px;
        text-align: center;
        padding: 15px 30px;
    }
    
    /* MAIN */
    
    .main--title-container{
        position: absolute;
        top: 20%;
        font-family: 'Raleway', sans-serif;
        padding-left: 70px;
        color: white;
    }
    
    .main--logo {
        width: 175px; 
        height: 155px;
    }
    
    .main--title {
        position: absolute;
        top: 135px;
        font-size: 7.5rem;
        line-height: 150px;
    }
    
    .main--title-content {
        position: absolute;
        top: 280px;
        font-size: 3.5rem;
        line-height: 5rem;
        width: 800px
    }
    
    .main--button-toapp {
        position: absolute;
        top: 500px;
        left: 0;
        margin-left: 70px;
    
        width: 150%;
        height: auto;
    
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 4rem;
        line-height: 6.5rem;
        text-align: center;
    
        color: #FFFFFF;
    }
    
    /*CARD*/
    
    .card--container-grid {
        position: absolute;
        top: 1050px;
    }
    
    .card--container {
        padding: 60px 30px 70px
    }

    .card--grid {
        margin-bottom: 150px;
    }
    
    .bird--image {
        width: 100px;
        height: 90px;
    }
    
    .people--image {
        width: 95px;
        height: 95px;
    }
    
    .card--container > h4 {
        font-size: 3.5rem;
        line-height: 6rem;
    color: #FFFFFF;
    }
    
    .card--container > p {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    
    /*FOOTER*/
    
    footer {
        position: absolute;
        top: 1950px;
    }
    
    footer > img {
        width: 120px;
        /* height: 90px */
    }
    
    .footer--title {
        font-size: 2rem;
        line-height: 3.5rem;
    }
    
    .mail--container {
        font-size: 2.25rem;
        line-height: 3rem;
        margin-bottom: 25px;
    }
}

@media (min-width: 1290px) and (max-width: 1400px) {    
    .header--list-items {
        font-size: 1.5rem;
    }
    
    /* MAIN */
    
    .main--logo {
        width: 150px; 
        height: 130px;
    }
    
    .main--title {
        position: absolute;
        top: 85px;
        font-weight: 500;
        font-size: 5.25rem;
        line-height: 175px;
    }
    
    .main--title-content {
        position: absolute;
        top: 225px;
        font-style: normal;
        font-weight: 300;
        font-size: 1.75rem;
        line-height: 50px;
        width: 400px
    }
    
    .main--button-toapp {   
        width: 135%;

        font-size: 2.25rem;
        line-height: 67px;
    }
    
    /*CARD*/
    
    .card--container-grid {
        margin: 0 200px;
    
        position: absolute;
        top: 700px;
    }
    
    .card--grid {
        margin-bottom: 100px;
    }
    
    .bird--image {
        width: 80px;
        height: 70px;
    }
    
    .people--image {
        width: 75px;
        height: 75px;
    }
    
    .card--container > h4 {   
    font-size: 2.25rem;
    line-height: 3.5rem;
    color: #FFFFFF;
    }
    
    .card--container > p {
    font-size: 1.75rem;
    line-height: 2.25rem;
    }
    
    /*FOOTER*/
    
    footer {
        position: absolute;
        top: 1350px;
    }
    
    .footer--title {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
    
    .mail--container {
        font-size: 1.75rem;
        line-height: 1.75rem;
        margin-bottom: 20px;
    }
}